import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import './InventoryOrder.css';
import Loader from '../../Common/Loader/Loader';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import 'firebase/compat/auth';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EyeIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/AddCircle';
import MinusIcon from '@mui/icons-material/RemoveCircle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StatusTag from '../../Common/StatusTag';
import OrderStats from '../../Common/OrderStats';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height:'5px'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#91BCB9',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#008080',
    },
  }),
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Client = new Api();
const moment = require('moment');
const CURRENT_BRANCH = "Got Tea - Golf Course Road";
const categories = [
  "Drink ingredients",
  "Food Ingredients",
  "Packaging",
  "Merchandise",
  "Disposables",
  "Petcan stickers (350 ml)",
  // "Petcan stickers (475 ml)",
  "Snacks box Sticker"
];

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  maxWidth:{xs:'92vw',sm:800},
  maxHeight:'80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflowY:'auto',
  p: 4,
};


class InventoryOrder extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            tabValue:0,
            processing:false,
            placingOrder:false,
            actionsLoading:false,
            materialsLoading:false,
            currentlyEditingOrder:{orderedBy:''},
            editingOrder:false,
            actionItemsArr:[],
            orderItemsArr:[],
            showAlert:false,
            alertType:"error",
            alertMessage:'',
            orderItemsObj:{},
            processedItemsObj:{},
            editingMaterialName:'',
            editMode:false,
            activeMatId:'',
            selectedItem:{},
            selectedQuantity:0,
            fieldErrors:{},
            showOrderModal:false,
            confirmedOrder:false,
            deleteConfirmationShown:false,
            browser : Utilities.getCurrentBrowser(),
        }
    }

    loadActions = ()=>{
      let mainThis = this;
      this.setState({
          actionsLoading:true
      },()=>{
          let statusArr = [];
          if(this.state.tabValue === 0) {
            statusArr = ["Order Placed","Acknowledged","Dispatched"];
          } else {
            statusArr = ["Delivered","Delivered Partially"];
          }
          Client.searchActions('',{actionType:['inventoryOrder'],branch:[CURRENT_BRANCH],status:statusArr}).then((loadedActions)=>{
              mainThis.setState({
                  actionItemsArr:loadedActions,
                  actionsLoading:false,
              });
          })
      })
  }

    loadMaterials = (callback)=>{
        this.setState({
            materialsLoading:true
        },()=>{
            Client.getActiveMaterials().then((data)=>{
                let oItems = [];        
                let catHash = {};        
                for(let item of data){
                  if(Utilities.isNullOrEmpty(catHash[item.category])){
                    catHash[item.category] = [];
                  }
                  catHash[item.category].push({ id:item.id, name : item.name, unitSize:parseFloat(item.unitSize), unit:item.unit, incrementUnit:parseFloat(item.incrementUnit),pricePerUnit:parseFloat(item.pricePerUnit)});
                }
                for(let c of categories){
                  oItems.push({ category : c, items : catHash[c]||[]});
                }
                callback(oItems);
            })
        })
    }

    goBack = ()=> {
      this.setState({
        confirmedOrder:false
      })
    }

    createOrder = ()=> {
      this.resetModal(()=>{
        this.setState({
          showOrderModal:true
        });
      });
    }

    editOrder = (order)=> {
      let orderItemsObj = _.cloneDeep(order.data.itemMap);
      let processedItemsObj = {};
      if(["Dispatched","Delivered","Delivered Partially"].includes(order.status)){
        processedItemsObj = _.cloneDeep(order.processedData.itemMap);
      }
      this.setState({
        orderItemsObj,
        processedItemsObj,
        currentlyEditingOrder:order,
        confirmedOrder:true,
        editingOrder:true,
        editMode:true,
        showOrderModal:true
      });
  }

    updateOrder = (statusToUpdate)=> {
      const {orderItemsObj,processedItemsObj,currentlyEditingOrder} = this.state;
      let action = {};
      action.actionType = "inventoryOrder";
      action.status = statusToUpdate || "Order Placed";
      let statusIsFine = true;
      if(action.status === "Delivered"){
        //Compare Objects
        for(let key in orderItemsObj){
          if(orderItemsObj[key].qty !== processedItemsObj[key].qty){
            statusIsFine = false;
            break;
          }
        }
        if(!statusIsFine){
          action.status = "Delivered Partially";
        }
        if(currentlyEditingOrder.note){
          action.note = currentlyEditingOrder.note;
        }
      }

      action.data = {itemMap : _.cloneDeep(orderItemsObj),items:[]};
      let items = [];
      let orderItemsArrCopy = _.cloneDeep(this.state.orderItemsArr);
      for(let cat of orderItemsArrCopy){
        if(cat && cat.items.length > 0){
          let temp = [];
          let newObj = {};
          for(let item of cat.items){
            if(orderItemsObj[item.id]){
              temp.push(item);
            }
          }
          newObj = {category:cat.category, items: temp};
          items.push(newObj);
        }
      }
      action.data.items = items;
      let mainThis = this;
      this.setState({
        actionsLoading:true,
        showOrderModal:false,
      },()=>{
        Client.updateAction(currentlyEditingOrder.id,action).then((res)=>{
        this.loadActions();
        this.resetModal();
        }).catch((err)=>{
          mainThis.setState({
            showAlert:true,
            alertType:"error",
            alertMessage:err,
          },()=>{
            this.loadActions();
          });
        });
      })
    }

    confirmOrder = ()=> {
      if(this.state.confirmedOrder){
        this.setState({
          placingOrder:true
        },()=>{
          this.placeOrder();
        });
      } else {
        this.setState({
          confirmedOrder:true
        });
      }
    }

    placeOrder = ()=> {
      const {orderItemsObj,currentlyEditingOrder} = this.state;
      let currentThis = this;
      currentThis.setState({
        processing:true
      },()=>{
        let action = {};
        action.actionType = "inventoryOrder";
        action.branch = CURRENT_BRANCH;
        action.orderedBy = currentlyEditingOrder.orderedBy;
        action.status = "Order Placed";
        action.data = {itemMap : {...orderItemsObj},items:[]};
        let items = [];
        let orderItemsArrCopy = [...this.state.orderItemsArr];
        for(let cat of orderItemsArrCopy){
          if(cat && cat.items.length > 0){
            let temp = [];
            let newObj = {};
            for(let item of cat.items){
              if(orderItemsObj[item.id]){
                temp.push(item);
              }
            }
            newObj = {category:cat.category, items: temp};
            items.push(newObj);
          }
        }
        
        action.data.items = items;
        Client.createNewAction(action).then((res)=>{
          currentThis.setState({
            confirmedOrder:false,
            showOrderModal:false,
            orderSuccess:true,
            showAlert:true,
            alertType:"success",
            processing:false,
            alertMessage:`Successfully created order with id ${res.id}`,
            placingOrder:false
          },()=>{
            currentThis.loadActions();
          })
        }).catch((err)=>{
          console.log(err);
          currentThis.setState({
            processing:false,
            confirmedOrder:false,
            // showOrderModal:false,
            orderSuccess:false,
            showAlert:true,
            alertType:"error",
            alertMessage:err,
            placingOrder:false
          })
        });
      });
    }

    componentDidMount(){
        this.loadMaterials((oItems)=>{
          this.setState({
            materialsLoading:false,
            orderItemsArr:oItems
          },()=>{
            this.loadActions();
          })
        });
    }

    handleSearch = (e)=>{
        this.setState({
            searchTerm:e.target.value
        },()=>{
            this.debouncedMaterials();
        });
    }

    showEditModal = (selectedItem,materialName, materialId, qty)=>{
        this.setState({
          selectedItem,
          activeMatId:materialId,
          editingMaterialName:materialName,
          showOrderModal:true,
          selectedQuantity:qty,
          editMode:true
        });
    }

    // showDeleteModal = (rowIndex,materialName, materialId)=>{
    //   this.setState({
    //     activeMatId:materialId,
    //     activeRowIndex:rowIndex,
    //     deleteConfirmationShown:true
    //   });
    // }

    handleShowAddItem = (e)=>{
      this.resetModal(()=>{
        this.setState({
          showOrderModal:true,
        });
      });
    }

    handleCloseModals = (e)=>{
      e.preventDefault();
      this.resetModal(()=>{
        this.setState({
          showOrderModal:false,
          deleteConfirmationShown:false
        });
      });
    }

    handleFieldChange = (fieldName, e)=>{
      let fieldErrorsCopy = {...this.state.fieldErrors};
      delete fieldErrorsCopy[fieldName];
      console.log("field name", fieldName);
      console.log("field errors", fieldErrorsCopy);
      if(fieldName === 'selectedItem'){
        let selectedItem = e;
        this.setState({
          selectedItem,
          fieldErrors:fieldErrorsCopy
        });
      } else {
        console.log("field errors state being set", fieldErrorsCopy);
        this.setState({
          [`${fieldName}`]:e.target.value,
          fieldErrors:fieldErrorsCopy
        },()=>{
  
        });
      }
    }

    resetModal = (cb)=>{
      this.setState({
        placingOrder:false,
        orderSuccess:false,
        orderErrMsg:'',
        processedItemsObj:{},
        currentlyEditingOrder:{orderedBy:''},
        orderItemsObj:{},
        confirmedOrder:false,
        activeMatId:undefined,
        activeRowIndex:undefined,
        editingOrder:false,
        editMode:false,
        fieldErrors:{},
        selectedItem:{},
        selectedQuantity:0
      },()=>{
        if(cb){
          cb();
        }
      });
    }

    increaseItemQuantity = (itemId, incrementUnit)=>{
      const {orderItemsObj} = this.state;
      let orderItemsObjCopy = _.cloneDeep(orderItemsObj);
      if(orderItemsObjCopy[itemId]){
        orderItemsObjCopy[itemId].qty += incrementUnit;
      } else {
        orderItemsObjCopy[itemId] = {qty:incrementUnit}
      }
      this.setState({
        orderItemsObj:orderItemsObjCopy
      },()=>{
        console.log(this.state.actionItemsArr);
      })
    }

    decreaseItemQuantity = (itemId, incrementUnit, keepZero)=>{
      const {orderItemsObj} = this.state;
      let orderItemsObjCopy = _.cloneDeep(orderItemsObj);
      if(orderItemsObjCopy[itemId]){
        orderItemsObjCopy[itemId].qty -= incrementUnit;
      } else {
        orderItemsObjCopy[itemId] = {qty:0}
      }
      if(orderItemsObjCopy[itemId].qty <= 0){
        if(keepZero){
          orderItemsObjCopy[itemId].qty = 0;
        } else {
          delete orderItemsObjCopy[itemId];
        }
      }

      this.setState({
        orderItemsObj:orderItemsObjCopy
      })
    }

    addItemToOrder = (e)=>{
      const {selectedQuantity, selectedItem, orderItemsObj, orderItemsArr} = this.state;
      let orderItemsObjCopy = {...orderItemsObj};
      let orderItemsArrCopy = orderItemsArr.slice();
      if(this.validateFields()){
        if(this.state.editMode){
          orderItemsObjCopy[selectedItem.id] = {
            quantity: selectedQuantity,
            data: selectedItem
          }
        } else {
          orderItemsObjCopy[selectedItem.id] = {
            quantity: selectedQuantity,
            data: selectedItem
          }
          if(!this.state.orderItemsObj[selectedItem.id]){
            orderItemsArrCopy.push(selectedItem.id);
          }
        }
        
        this.setState({
          orderItemsArr:orderItemsArrCopy,
          orderItemsObj:orderItemsObjCopy,
          showOrderModal:false
        })
      }
    }

    handleTabChange = (event, newValue)=>{
      this.setState({
        actionsLoading:true,
        tabValue:newValue
      },()=>{
        this.loadActions();
      })
    }

    validateFields = ()=>{
      let errors = {};
      if(Utilities.isNullOrEmpty(this.state.selectedItem)){
        errors['selectedItem'] = 'Item is required.';
      }
      if(Utilities.isNullOrEmpty(this.state.selectedQuantity) || this.state.selectedQuantity <= 0){
        errors['selectedQuantity'] = 'Quantity cannot be zero.';
      }
      if(!(Utilities.isNullOrEmpty(errors))){
        console.log(errors);
        this.setState({
          fieldErrors:errors
        });
        return false;
      }
      return true;
    }

    renderActionsTable(){
      const {actionItemsArr } = this.state;
      return <TableContainer sx={{maxHeight:'60vh',padding:0}} component={Paper}>
        <Table sx={{ minWidth: 650,padding:0 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Details</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{padding:0}}>
            {this.state.actionsLoading ? <TableRow
                key={'loader'}
              >
                <TableCell component="th" scope="row" colSpan={3} sx={{textAlign:'center'}}>
                <Loader/>
                </TableCell>
                </TableRow> : <>{actionItemsArr.map((row,index) => (
              <TableRow
                key={`${row}-${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                <Typography variant="subtitle1" component="div" sx={{textAlign:'left'}}>
                <b>Order ID</b>: {row.id}<br/>
                <b>Date</b>: {moment(row.createdAt).format("ddd DD-MMM-YYYY, hh:mm A")}
                </Typography>
                </TableCell>
                <TableCell align="left" component="th" scope="row">
                <StatusTag status={row.status}/>
                </TableCell>
                <TableCell align="left">
                <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                <Button color="success" disabled={this.state.processing} variant="contained" children={<EyeIcon />} onClick={()=>this.editOrder(row)}/>
                </Stack>
                  </TableCell>
              </TableRow>
            ))}</>}
            
          </TableBody>
        </Table>
      </TableContainer>;
    }
    
    handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      this.setState({
        showAlert:false
      });
    }
  
    alertAction = ()=> (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={this.handleCloseAlert}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
    
    render(){
        const {orderItemsObj,processedItemsObj, orderItemsArr, tabValue, showAlert, alertType, alertMessage} = this.state;

        return  <div className='inventoryOrderContainer'><ReportsHeader/>
        <Box sx={{padding:0,maxWidth:'92vw'}}>
        <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={this.handleCloseAlert}
        action={this.alertAction()}
      >
        <Alert onClose={this.handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
        {alertMessage}
        </Alert>
        </Snackbar>
        <Modal
        onClose={this.handleCloseModals}
        open={this.state.showOrderModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={modalStyle}>
        <Box>
        {this.state.editingOrder ? <OrderStats orderData={this.state.currentlyEditingOrder}/> : ''}
        {this.state.confirmedOrder ? <TableContainer sx={{maxHeight:["Dispatched"].includes(this.state.currentlyEditingOrder.status) ? '40vh' : '60vh'}} component={Paper}>
          <Table aria-label="simple table" stickyHeader>
            <TableBody>{orderItemsArr.map((cat,oIndex) => { 
            return  cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).length ? <TableRow key={`${cat.category}-${oIndex}`}><TableCell>
            <Table aria-label="simple table">
            <TableHead>
              <TableRow>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">{cat.category}</TableCell>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">Amount Ordered</TableCell>
              {(this.state.confirmedOrder && ["Dispatched"].includes(this.state.currentlyEditingOrder.status)) ? <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">
                Amount Sent</TableCell> : null}
                {(this.state.confirmedOrder && ["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ? <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">
                Amount Received</TableCell> : null}
              </TableRow>
            </TableHead>
              <TableBody>
              {cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).map((row,index) => (
              <TableRow
              key={`${row}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
              <TableCell align="left" component="th" scope="row" sx={{width:'30%'}}>
              <Typography variant="subtitle1" component="div" sx={{textAlign:'left'}}>
              {row.name}
              </Typography>
              </TableCell>
              <TableCell align="left">
                <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                <Typography variant="subtitle1" component="div" sx={{minWidth:50,textAlign:'center'}}>
                {(this.state.confirmedOrder && ["Order Placed","Acknowledged","Dispatched","Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ?
                (orderItemsObj[row.id] ? `${parseFloat(orderItemsObj[row.id].qty)} units` : '') : ''
                } {(this.state.confirmedOrder && ["Order Placed","Acknowledged","Dispatched","Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ?
                `(${orderItemsObj[row.id] ? (parseFloat(orderItemsObj[row.id].qty) * parseFloat(row.unitSize)) : 0} ${row.unit})` : ''}
                {!this.state.editMode ? `${orderItemsObj[row.id].qty} units (${orderItemsObj[row.id] ? (parseFloat(orderItemsObj[row.id].qty) * parseFloat(row.unitSize)) : 0} ${row.unit})` :null}
                </Typography>
                </Stack>
              </TableCell>
              {["Dispatched","Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status) ? <TableCell align="left">
                <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                <Typography variant="subtitle1" component="div" sx={{minWidth:50,textAlign:'center',color:(this.state.confirmedOrder ?
                (parseFloat(orderItemsObj[row.id].qty) !== parseFloat(processedItemsObj[row.id].qty) ? "red" : "black") : 'unset'),fontWeight:((this.state.confirmedOrder) ?
                (parseFloat(orderItemsObj[row.id].qty) !== parseFloat(processedItemsObj[row.id].qty) ? "bold" : "regular") : 'unset')}}>
                {this.state.confirmedOrder ?
                (processedItemsObj[row.id] ? `${processedItemsObj[row.id].qty} units` : 0):null}
                {this.state.confirmedOrder ?
                `(${processedItemsObj[row.id] ? (parseFloat(processedItemsObj[row.id].qty) * parseFloat(row.unitSize)) : 0} ${row.unit})` : ''}
                </Typography>
                </Stack>
              </TableCell> : null}
              </TableRow>
              ))}
              </TableBody>
            </Table>      
            </TableCell>
          </TableRow> : null;}
          )}
          </TableBody></Table>
          </TableContainer> : orderItemsArr.map((cat,indexCat) => { 
          return <Accordion key={`${cat}-accordion-${indexCat}`}>
          <AccordionSummary
          sx={{background:'#F3F1E4'}}
          expandIcon={<ExpandMoreIcon />}
          >
          <Typography variant="subtitle1" component="h6" sx={{fontWeight:"bold",color:"#47908a",textAlign:'left'}}>{cat.category}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{padding:0}}>
          <TableContainer sx={{maxHeight:'60vh',width:'auto'}} component={Paper}>
          <Table aria-label="simple table" stickyHeader>
          <TableHead>
          <TableRow>
          <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">Name</TableCell>
          <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">Amount Ordered</TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
          {this.state.materialsLoading ? <TableRow
          key={'loader'}
          >
          <TableCell component="th" scope="row" colSpan={2} sx={{textAlign:'center'}}>
          <Loader/>
          </TableCell>
          </TableRow> : <>{cat.items.map((row,index) => (
          <TableRow
          key={`${row}-${index}`}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
          <TableCell align="left" component="th" scope="row" sx={{width:'38vw'}}>
            <Typography variant="subtitle1" component="div" sx={{textAlign:'left'}}>
          {row.name}
          </Typography>
          </TableCell>
          <TableCell align="left">
          <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
          <Button color="error" variant="contained" children={<MinusIcon />} onClick={()=>{this.decreaseItemQuantity(row.id,row.incrementUnit,false)}}/>
          <Typography variant="subtitle1" component="div" sx={{minWidth:50,textAlign:'center'}}>
          {orderItemsObj[row.id] ? orderItemsObj[row.id].qty : 0}
          </Typography>
          <Button color="success" variant="contained" children={<AddIcon />} onClick={()=>{this.increaseItemQuantity(row.id,row.incrementUnit)}}/>
          <Typography variant="subtitle1" component="div" sx={{minWidth:50,textAlign:'center'}}>
          {orderItemsObj[row.id] ? `${parseFloat(orderItemsObj[row.id].qty * row.unitSize)} ${row.unit}` : ''}
          </Typography>
          </Stack>
            </TableCell>
          </TableRow>
          ))}</>}
          </TableBody>
          </Table>
          </TableContainer>
          </AccordionDetails>
          </Accordion>;}
        )}
        {
        ((this.state.confirmedOrder && !["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) || 
        (this.state.confirmedOrder && ["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) && this.state.currentlyEditingOrder.orderedBy) ? 
        <TextField
          sx={{mt:3}}
          fullWidth={true}
          label="Order Placed By:"
          rows={1}
          disabled={["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)}
          value={this.state.currentlyEditingOrder.orderedBy}
          onChange={(event) => {
            let currentlyEditingOrder = {...this.state.currentlyEditingOrder};
            currentlyEditingOrder.orderedBy = event.target.value;
            this.setState({
              currentlyEditingOrder
            });
          }}
        /> : null}
        {["Dispatched"].includes(this.state.currentlyEditingOrder.status) ? <>
        <TextField
          sx={{mt:3}}
          fullWidth={true}
          label="Additional Comment / Notes"
          multiline
          rows={3}
          disabled={["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)}
          value={this.state.currentlyEditingOrder.note}
          onChange={(event) => {
            let currentlyEditingOrder = {...this.state.currentlyEditingOrder};
            currentlyEditingOrder.note = event.target.value;
            this.setState({
              currentlyEditingOrder
            });
          }}
        />
        </> : null}
        {(["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status) && !Utilities.isNullOrEmpty(this.state.currentlyEditingOrder.note))? 
        <Typography
          sx={{mt:2}}
        >
          <b>Note: </b>{this.state.currentlyEditingOrder.note}
        </Typography>
         : null}
        </Box>
      <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%' justifyContent="flex-end" sx={{mt:2}}>
      {(this.state.confirmedOrder && ((this.state.editingOrder && this.state.currentlyEditingOrder.status === "Order Placed") || !this.state.editingOrder)) ? <Button color="success" variant="contained" onClick={this.goBack}>
        {'Edit'}
      </Button> : ''}
      {(this.state.editingOrder && this.state.confirmedOrder) ? 
      <>{this.state.currentlyEditingOrder.status === "Order Placed" ? <Button color="success" variant="contained" onClick={()=>this.updateOrder("Order Placed")} disabled={Utilities.isNullOrEmpty(this.state.orderItemsObj) || this.state.processing }>
      {'Update Order'}
    </Button> : ''}</> : 
      <Button color="success" variant="contained" onClick={this.confirmOrder} disabled={Utilities.isNullOrEmpty(this.state.orderItemsObj) || this.state.placingOrder || this.state.processing || (this.state.confirmedOrder && this.state.currentlyEditingOrder.orderedBy.length <=2)}>
        {this.state.confirmedOrder ? 'Place Order' : 'Confirm Order'}
      </Button>}
      {(this.state.confirmedOrder && ["Dispatched"].includes(this.state.currentlyEditingOrder.status)) ? <Button color="success" variant="contained" onClick={()=>this.updateOrder("Delivered")} disabled={Utilities.isNullOrEmpty(this.state.orderItemsObj) || this.state.processing}>
        Mark as Received
      </Button> : ''}
      <Button color="error" variant="contained" onClick={this.handleCloseModals}>
        {'Close'}
      </Button>
      </Stack>
        </Box>
        </Modal>
        <Button color="success" variant="contained" onClick={this.createOrder} disabled={this.state.processing}>
          New Order
        </Button>
        <Box
          sx={{ flexGrow: 1, bgcolor: '#008080', display: 'flex', mt:2}}
        >
          <StyledTabs
          value={tabValue}
          onChange={this.handleTabChange}
          aria-label="styled tabs example"
          >
            <StyledTab label="Pending" />
            <StyledTab label="Done" />
          </StyledTabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
      {this.renderActionsTable()}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
      {this.renderActionsTable()}
      </TabPanel>
      </Box>
      </div>
    } 
}

export default InventoryOrder;